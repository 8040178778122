import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Table, Select, Popover, Input } from 'antd';
import dayjs from 'dayjs';
import locale from 'antd/locale/es_ES';
import { fetcher } from '../../utils/requests';
import Loading from '../../components/loading/Loading';
import { DollarCircleOutlined, SearchOutlined } from '@ant-design/icons';
import './Payments.scss';
import { formatCurrency } from '../../utils/formatters';

const { Search } = Input;
const PAYMENT_TYPES = ['', 'Efectivo', 'Transferencia'];
const CUTOFF_DATES = [15, 30];

const TableSelect = ({ text, onChange, record }) => {
	const [selectedValue, setSelectedValue] = useState(text);
	const handleChange = value => {
		setSelectedValue(value);
		onChange(value, record);
	};
	return (
		<Select
			style={{ width: '130px' }}
			defaultValue={PAYMENT_TYPES[0]}
			options={PAYMENT_TYPES.map(v => ({ value: v, label: v }))}
			value={{ value: selectedValue, label: selectedValue }}
			onChange={handleChange}
		/>
	);
};

const DETAILS_FIELDS = [
	'fullname',
	'personalId',
	'planSpeed',
	'cellphone',
	'address',
	'createdDate',
];
const defaultModifier = (name, value) => (
	<>
		<span>{name}:</span>
		<span>{value}</span>
	</>
);
const FIELD_MODIFIERS = {
	fullname: value => defaultModifier('Nombre', value),
	personalId: value => defaultModifier('Cédula', value),
	cellphone: value => defaultModifier('Teléfono', value),
	address: value => defaultModifier('Dirección', value),
	planSpeed: value => defaultModifier('Plan Contratado', value),
	createdDate: value =>
		defaultModifier(
			'Fecha ingreso',
			value ? dayjs(new Date(value)).format('DD/MM/YYYY') : value,
		),
};

const tableColumns = [
	{
		title: 'Cliente',
		dataIndex: 'fullname',
		key: 'fullname',
		width: 150,
	},
	{
		title: 'Tipo de Pago',
		dataIndex: 'paymentType',
		key: 'paymentType',
		width: 150,
	},
];

const NoPayments = () => (
	<div className='empty-payments'>
		<span>No hay pagos pendientes</span>
		<DollarCircleOutlined style={{ color: 'green', fontSize: 30 }} />
	</div>
);

const getPayments = (date, selectedPeriod, setPayments, setIsLoading) =>
	fetcher(
		'payments',
		{
			params: [
				['period', `${date.format('YYYY-MM')}`],
				['cutoffDate', selectedPeriod],
			],
		},
		payments => {
			setPayments(payments.sort((a, b) => (a.key < b.key ? -1 : 1)));
			setIsLoading(false);
		},
		error => setIsLoading(false),
	);

const Payments = () => {
	const [selectedDate, setSelectedDate] = useState(dayjs());
	const [selectedPeriod, setSelectedPeriod] = useState(CUTOFF_DATES[0]);
	const [payments, setPayments] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [searchValue, setSearchValue] = useState('');

	useEffect(() => {
		if (selectedDate) {
			setPayments([]);
			setIsLoading(true);
			getPayments(selectedDate, selectedPeriod, setPayments, setIsLoading);
		}
	}, [selectedDate, selectedPeriod]);

	const onFinish = () => {
		setIsLoading(true);
		fetcher(
			'payments',
			{
				method: 'POST',
				body: {
					payments: payments.filter(p => p.isPayed),
					duePeriod: selectedDate.format('YYYY-MM'),
					paymentDate: dayjs().format(),
				},
			},
			() => {
				setPayments([]);
				setIsLoading(true);
				getPayments(selectedDate, selectedPeriod, setPayments, setIsLoading);
			},
			error => {
				console.log(error);
				setIsLoading(false);
			},
		);
	};

	const handleSelectPaymentType = (value, record) => {
		const newPayment = { ...record, paymentType: value, isPayed: !!value };
		const otherPayments = payments.filter(p => p.key !== record.key);
		setPayments([newPayment, ...otherPayments].sort((a, b) => (a.key < b.key ? -1 : 1)));
	};

	const filteredPayments =
		searchValue !== ''
			? payments.filter(
					payment =>
						!!Object.keys(payment).some(key =>
							payment[key]?.toString().includes(searchValue),
						),
			  )
			: payments;

	const columns = tableColumns.map(col => ({
		...col,
		...(col.dataIndex === 'paymentType'
			? {
					render: (text, record) => (
						<TableSelect
							value={text || 'Sin Pagar'}
							onChange={handleSelectPaymentType}
							record={record}
						/>
					),
			  }
			: {}),
		...(col.dataIndex === 'fullname'
			? {
					title: (
						<div className='table-search-title'>
							<span>{col.title}</span>
							<Popover
								destroyTooltipOnHide={true}
								content={
									<Search
										placeholder='Buscar'
										onChange={({ target: { value } }) => setSearchValue(value)}
										value={searchValue}
									/>
								}
							>
								<SearchOutlined
									style={{ color: searchValue ? 'red' : '', fontSize: 16 }}
								/>
							</Popover>
						</div>
					),
					render: (text, record) => (
						<>
							<span>{text}</span>
							<br />
							<span>{formatCurrency(record.negociatedValue)}</span>
							<br />
							<Popover
								title='Detalle del Cliente'
								placement='bottomRight'
								destroyTooltipOnHide={true}
								content={
									<div className='payments-popover-content'>
										{DETAILS_FIELDS.map(field => (
											<div className='popover-field' key='field'>
												{FIELD_MODIFIERS[field](record[field])}
											</div>
										))}
									</div>
								}
							>
								<Button className='details-button' type='text'>
									Detalles
								</Button>
							</Popover>
						</>
					),
			  }
			: {}),
	}));

	return (
		<>
			{isLoading && <Loading />}
			<div className='picker-options'>
				<DatePicker
					locale={locale}
					maxTagCount='responsive'
					size='small'
					picker='month'
					defaultValue={dayjs(selectedDate, 'YYYY-MM')}
					onChange={setSelectedDate}
					allowClear={false}
				/>
				<Select
					style={{ width: '60px' }}
					defaultValue={15}
					options={CUTOFF_DATES.map(v => ({ value: v, label: v }))}
					onChange={setSelectedPeriod}
				/>
			</div>
			{payments.length ? (
				<Table
					className='payments-table'
					columns={columns}
					dataSource={filteredPayments}
					pagination={{
						position: ['bottomCenter'],
						responsive: true,
						pageSizeOptions: ['5', '10', '25', '50'],
						alignCenter: 'center'
					}}
					scroll={{ x: 'max-content', y: '350px' }}
				/>
			) : (
				<NoPayments />
			)}
			<div className='bottom-options'>
				<div className='unpaid-totals'>
					{!!payments.length && (
						<>
							<span className='total-users'>
								<span>Usuarios restantes:</span>
								<span>{payments.length}</span>
							</span>
							<span className='total-currency'>
								<span>Total sin pagar:</span>
								<span>
									{formatCurrency(
										payments.reduce(
											(acc, curr) => acc + curr.negociatedValue,
											0,
										),
									)}
								</span>
							</span>
						</>
					)}
				</div>
				<Button
					type='primary'
					onClick={onFinish}
					disabled={!payments.filter(p => p.isPayed).length}
				>
					Guardar
				</Button>
			</div>
		</>
	);
};

export default Payments;
