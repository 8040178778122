import { Layout, Tag, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import Loading from '../../components/loading/Loading';
import { Content, Header } from 'antd/es/layout/layout';
import { fetcher } from '../../utils/requests';
import { formatCurrency } from '../../utils/formatters';
import dayjs from 'dayjs';
import TableComponent from '../../components/table/TableComponent';
import { QuestionCircleOutlined, WarningOutlined } from '@ant-design/icons';
import UpdateConnectionModal from './modals/UpdateConnectionModal';
import { useForm } from 'antd/es/form/Form';
import './Connections.scss';

const HEADER_FORMATTERS = {
	client: () => 'Cliente',
	router: () => 'IP',
	plan: () => 'Plan',
	negociatedValue: () => 'Pago',
	comments: () => 'Comentarios conexión',
	paymentDate: () => 'Corte',
	createdDate: () => 'Fecha Ingreso',
	state: () => 'Estado',
	address: () => 'Dirección',
};

const DATA_FORMATTERS = {
	negociatedValue: v => formatCurrency(v),
	client: (v, clients) => {
		const client = clients?.find(p => p.id === v);
		return client ? `${client.firstName} ${client.lastName} ${client.personalId}` : '';
	},
	router: (v, routers) => routers?.find(p => p.id === v)?.ip,
	plan: (v, plans) => plans?.find(p => p.id === v)?.planSpeed,
	createdDate: d => dayjs(new Date(d)).format('DD/MM/YYYY'),
	state: v => (v === 'active' ? 'Activo' : 'Inactivo'),
};

const getConnections = (setColumns, setConnections, setDependencies, setIsLoading) => {
	fetcher(
		'connections/schema',
		{ method: 'GET' },
		response => {
			setColumns(response);
			fetcher(
				'connections',
				{ method: 'GET' },
				response => {
					const { connections: cons, dependencies: deps } = response;
					setConnections(cons);
					setDependencies(deps);
					setIsLoading(false);
				},
				error => {
					console.log(error);
					setIsLoading(false);
				},
			);
		},
		error => {
			setIsLoading(false);
			console.log(error);
		},
	);
};
const Connections = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [connections, setConnections] = useState([]);
	const [dependencies, setDependencies] = useState({});
	const [columns, setColumns] = useState([]);
	const [selectedRow, setSelectedRow] = useState(null);
	const [openModal, setOpenModal] = useState(null);
	const [form] = useForm();

	useEffect(() => {
		setIsLoading(true);
		getConnections(setColumns, setConnections, setDependencies, setIsLoading);
	}, []);

	const handleClick = value => {
		console.log(value);
		setSelectedRow(value);
		setOpenModal(true);
	};

	const handleCancel = () => {
		setOpenModal(null);
		setSelectedRow(null);
	};

	const handleOk = value => {
		setOpenModal(false);
		setSelectedRow(null);
		setIsLoading(true);
		fetcher(
			'connections',
			{ method: 'PUT', body: value },
			() => getConnections(setColumns, setConnections, setDependencies, setIsLoading),
			error => {
				console.log(error);
				setIsLoading(false);
			},
		);
	};

	const handleDelete = value => {
		setOpenModal(false);
		setSelectedRow(null);
		setIsLoading(true);
		fetcher(
			'connections',
			{ method: 'delete', body: value },
			id => {
				getConnections(setColumns, setConnections, setDependencies, setIsLoading);
			},
			error => {
				console.log(error);
				setIsLoading(false);
			},
		);
	};

	return (
		<>
			{isLoading && <Loading />}
			<Layout className='connections'>
				<Header className='connections-header'>
					<span className='title'>Conexiones</span>
					<Tooltip title='Modulo para administrar las conexiones, usalo para hacer traslados o cambiar la información de una conexión.'>
						<QuestionCircleOutlined style={{ color: 'blue', fontSize: 14 }} />
					</Tooltip>

					<span className='subtitle'>
						<Tag className='tag' color='yellow-inverse' icon={<WarningOutlined />}>
							Importante
						</Tag>
						<span className='message'>
							Las modificaciones en este modulo pueden inactivar conexiones existentes
						</span>
					</span>
				</Header>
				<Content className='connections-content'>
					<TableComponent
						headerFormatters={HEADER_FORMATTERS}
						dataFormatters={DATA_FORMATTERS}
						data={connections}
						dependencies={dependencies}
						columns={columns}
						onClick={handleClick}
						perPage={3}
						searchFields={[
							'client',
							'plan',
							'router',
							'paymentDate',
							'state',
							'createdDate',
						]}
					/>
					{openModal && (
						<UpdateConnectionModal
							open={openModal}
							form={form}
							dependencies={dependencies}
							values={{
								...dependencies?.router.find(r => (r.id = selectedRow.router)),
								...selectedRow,
							}}
							onCancel={handleCancel}
							onOk={handleOk}
							onDelete={handleDelete}
						/>
					)}
				</Content>
			</Layout>
		</>
	);
};

export default Connections;
